.intro {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5.6rem;
  padding: 0 var(--margin);

  @media (--laptop) {
    row-gap: 12rem;
  }

  &__list {
    list-style: disc;
    list-style-position: inside;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1.6rem;

    @media (--laptop) {
      row-gap: 3.2rem;
    }

    li {
      font-size: 1.6rem;

      @media (--laptop) {
        font-size: 2.4rem;
      }
    }
  }
}
