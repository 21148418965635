.projects {
  width: 100%;
  padding: 0 var(--margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.2rem;

  @media (--laptop) {
    row-gap: 5.6rem;
  }

  &__paragraph {
    @media (--laptop) {
      width: var(--columns-8);
    }
  }

  &__cultura-images {
    width: 100%;
  }

  &__event-images {
    width: 100%;
  }

  &__numbers-title {
    margin-top: 1.6rem;
  }

  &__numbers {
    margin-bottom: 3.2rem;
  }
}
