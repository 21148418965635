main {
  width: 100%;
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6.4rem;
  z-index: var(--main-z-index);
  opacity: 1;
  transition: opacity var(--transition-duration--slower)
    var(--transition-ease--out-expo);
    padding-top: 6.4rem;

  .loader-hidden & {
    opacity: 1;
  }

  @media (--laptop) {
    padding-top: 20rem;
    row-gap: 9.6rem;
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.header-in-view-trigger {
  width: 1px;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  &__last {
    left: calc(100% - 100vw);
  }
}
