.contattami {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.2rem;

  &__title {
    text-align: center;
    box-sizing: content-box;
    padding: 0 var(--margin);
  }

  &__marquee {
    width: 100%;
  }

  .marquee {
    width: 100%;
    padding: 2.4rem 0;
  }
}
