.chi-sono {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.2rem;
  padding: 0;

  @media (--laptop) {
    row-gap: 8rem;
  }

  &__intro {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 var(--margin);

    &__image {
      width: 100%;
      height: 14.2rem;
      overflow: hidden;
      border-radius: 20rem;
      background-image: url(https://i.imgur.com/f1jnGR7.jpg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      @media (--laptop) {
        height: 40rem;
      }
    }

    &__text {
      text-align: center;
      margin-top: -2rem;

      @media (--laptop) {
        margin-top: -4rem;
      }
    }
  }

  &__info {
    padding: 0 var(--margin);

    @media (--laptop) {
      width: var(--columns-8);
      box-sizing: content-box;
    }
  }

  &__carousel {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;

    &__wrapper {
      width: 100%;

      &__images {
        width: fit-content;
        display: flex;
        column-gap: 2.4rem;
        padding: 0 var(--margin);

        &__image {
          height: 22rem;

          @media (--laptop) {
            height: 44rem;
          }

          &.image img {
            position: relative;
            width: auto;
          }
        }
      }
    }

    &__arrows {
      width: 100%;
      padding: 0 var(--margin);
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__arrow {
        width: 4.5rem;
        height: 0.8rem;
        box-sizing: content-box;
        padding: 0.8rem 0;
        background-image: url(../../assets/icons/arrow--left.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        opacity: 1;
        will-change: opacity;
        transition: var(--transition-duration--fast)
          var(--transition-ease--out-quint);

        &--right {
          background-image: url(../../assets/icons/arrow--right.svg);
        }

        &--disabled {
          opacity: 0.3;
        }
      }
    }
  }
}
