.images-brick {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--gutter);
  row-gap: var(--gutter);

  @media (--laptop) {
    grid-template-columns: repeat(4, 1fr);
  }

  &__image {
    aspect-ratio: 156/217;

    @media (--laptop) {
      aspect-ratio: 224/303;
    }
  }
}
