.footer {
  width: 100%;
  box-sizing: border-box;
  padding: 4.8rem var(--margin) 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4.8rem;

  @media (--laptop) {
    padding: 8rem var(--margin) 2rem;
    row-gap: 6.4rem;
  }

  &__logo-and-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 4rem;

    @media (--laptop) {
      flex-direction: row;
      column-gap: calc(var(--column) + 2 * var(--gutter));
    }

    &__logo {
      width: 8rem;

      @media (--laptop) {
        width: var(--columns-2);
        margin-right: var(--columns-1);
      }

      svg g g {
        animation: eye 8s 2s infinite;
      }

      @keyframes eye {
        0% {
          transform: translateX(0);
          transition-timing-function: var(--transition-ease--out-quart);
        }

        12.5% {
          transform: translateX(35%);
          transition-timing-function: var(--transition-ease--out-quart);
        }

        25% {
          transform: translateX(-35%);
          transition-timing-function: var(--transition-ease--out-quart);
        }

        37.5% {
          transform: translateX(0);
          transition-timing-function: var(--transition-ease--out-quart);
        }

        100% {
          transform: translateX(0);
        }
      }

      svg {
        width: 100%;
        height: 100%;
      }

      svg path {
        fill: currentColor;
      }

      svg path.logo__eye-mask {
        fill: none;
      }

      svg line {
        stroke: currentColor;
      }
    }

    &__menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 0.8rem;

      @media (--laptop) {
        width: var(--columns-4);
      }
    }

    &__contacts {
      display: flex;
      align-items: flex-start;
      column-gap: var(--gutter);

      @media (--laptop) {
        flex-direction: column;
        align-self: stretch;
        justify-content: space-between;
      }

      &__contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 1.2rem;
        min-width: var(--columns-3);
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1.2rem;

    @media (--laptop) {
      flex-direction: row;
      column-gap: calc(var(--column) + 2 * var(--gutter));
    }

    &__credits {
      display: flex;
      align-items: flex-start;
      column-gap: var(--gutter);

      > div {
        min-width: var(--columns-4);
      }
    }
  }
}
