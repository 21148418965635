:root {
  --black: #000000;
  --yellow: #CCFE8B;
  --white: #ffffff;
}

.c-black {
  color: var(--black);
}

.c-yellow {
  color: var(--yellow);
}

.c-white {
  color: var(--white);
}

.bc-black {
  background-color: var(--black);
}

.bc-yellow {
  background-color: var(--yellow);
}

.bc-white {
  background-color: var(--white);
}
