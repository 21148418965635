.carousel {
  user-select: none;
  width: 100%;
  overflow: hidden;
  cursor: grab;

  @media (--laptop) {
  }

  &__items {
    @media (--laptop) {
    }

    &__item {
      pointer-events: none;
    }
  }
}
