@font-face {
  font-family: "Iskry";
  src: url("/assets/font/IskryBold-Bold.woff2") format("woff2"),
    url("/assets/font/IskryBold-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Iskry";
  src: url("/assets/font/IskryRegular.woff2") format("woff2"),
    url("/assets/font/IskryRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');