.cta-card {
  width: 100%;
  padding: 3.2rem var(--column);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.2rem;
  text-align: center;
  border-radius: 5rem;

  @media (--laptop) {
    padding: 3.6rem 4rem 4.8rem;
  }

  &:only-child {
    @media (--laptop) {
      padding: 3.6rem var(--columns-1) 4.8rem;
    }
  }

  &__title {
    order: 1;
  }

  &__image {
    order: 2;
    width: var(--columns-6);
    aspect-ratio: 244/251;

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: var(--yellow);
      mix-blend-mode: color;
    }

    &::after {
      mix-blend-mode: multiply;
      z-index: 2;
    }

    @media (--laptop) {
      width: var(--columns-4);
      height: 25rem;
      margin-bottom: 0.8rem;
    }

    .cta-card:only-child & {
      order: 3;

      @media (--laptop) {
        width: var(--columns-8);
        margin-bottom: 1.6rem;
      }
    }
  }

  &__text {
    order: 3;

    @media (--laptop) {
      margin-bottom: 0.8rem;
    }

    .cta-card:only-child & {
      order: 2;

      @media (--laptop) {
        margin-bottom: 0.8rem;
      }
    }
  }

  &__button {
    order: 4;
  }
}
