* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html,
body {
  box-sizing: border-box;
}

html {
  width: 100%;
  font-size: 10px;

  @media (--laptop) {
    font-size: 0.6944444444vw;
  }
}

body {
  background-color: var(--black);
  width: 100vw;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

select,
button,
input,
textarea {
  padding: 0;
  border: none;
  outline: none;
  background: none;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
