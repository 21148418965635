.coaching {
  width: 100%;
  padding: 4.8rem var(--margin) 6.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4.8rem;

  @media (--laptop) {
    padding: 8.8rem var(--margin);
    row-gap: 8rem;
  }

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.6rem;
    text-align: center;

    @media (--laptop) {
      width: 100%;
      row-gap: 2.4rem;
    }

    &__text {
      margin-top: 1.6rem;

      @media (--laptop) {
        width: var(--columns-8);
        margin-top: 2.4rem;
      }
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;

    @media (--laptop) {
      flex-direction: row;
      column-gap: var(--gutter);
    }
  }
}
