.numbers-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--gutter);
  row-gap: var(--gutter);

  @media (--laptop) {
    grid-template-columns: repeat(4, 1fr);
  }

  &__number {
    aspect-ratio: 156/128;
    border: 1px solid var(--yellow);
    border-radius: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (--laptop) {
      aspect-ratio: 224/184;
    }
  }
}
