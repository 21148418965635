.marquee {
  overflow: hidden;

  &__inner {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
  }

  &__content {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    white-space: pre;

    span {
      margin: 0 0.75rem;
    }
  }

  &__content-dot {
    display: block;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 0.7rem;
    border: 2px solid currentColor;
  }
}
