.showreel {
  width: 100%;
  padding: 0 var(--margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.2rem;

  &__title {
    text-align: center;
  }

  &__video {
    width: 100%;
    height: 52.5rem;
    object-fit: cover;
    display: none;

    @media (--laptop) {
      display: block;
    }

    &--mobile {
      display: block;

      @media (--laptop) {
        display: none;
      }
    }
  }
}
