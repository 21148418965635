.header {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4rem;
  padding: 4rem var(--margin);
  z-index: var(--header-z-index);
  opacity: 1;
  transition: opacity var(--transition-duration--slower)
      var(--transition-ease--out-expo),
    transform var(--transition-duration--slow) var(--transition-ease--out-quint);

  .loader-hidden & {
    opacity: 1;
  }

  @media (--laptop) {
    padding: 6rem var(--margin);
    flex-direction: row;
    justify-content: space-between;
  }

  &--hidden {
    transform: translateY(-100%);
  }

  &__logo {
    width: 8rem;

    @media (--laptop) {
      width: var(--columns-2);
    }

    svg g g {
      animation: eye 8s 2s infinite;
    }

    @keyframes eye {
      0% {
        transform: translateX(0);
        transition-timing-function: var(--transition-ease--out-quart);
      }

      12.5% {
        transform: translateX(35%);
        transition-timing-function: var(--transition-ease--out-quart);
      }

      25% {
        transform: translateX(-35%);
        transition-timing-function: var(--transition-ease--out-quart);
      }

      37.5% {
        transform: translateX(0);
        transition-timing-function: var(--transition-ease--out-quart);
      }

      100% {
        transform: translateX(0);
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }

    svg path {
      fill: currentColor;
    }

    svg path.logo__eye-mask {
      fill: none;
    }

    svg line {
      stroke: currentColor;
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (--laptop) {
      width: fit-content;
      column-gap: 1.6rem;
    }
  }
}
