.video {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--black);
    opacity: 0.4;
    z-index: 1;
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    content: "";
    width: var(--columns-2);
    height: var(--columns-2);
    background-image: url(../../assets/icons/play-button.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    pointer-events: none;
    opacity: 1;
  }

  &::before,
  &::after {
    will-change: opacity;
    transition: opacity var(--transition-duration--faster)
      var(--transition-ease--out-quint);
  }

  &--play {
    &::before,
    &::after {
      opacity: 0;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__controls {
    width: 100%;
    position: absolute;
    top: calc(100% + 1.6rem);
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (--laptop) {
      top: calc(100% + 1.6rem);
      transform: none;
    }

    &__control {
      width: var(--columns-2);
      overflow: hidden;
      cursor: pointer;
      flex-shrink: 0;

      @media (--laptop) {
        width: var(--column);
      }

      > p {
        will-change: transform;
        transition: transform var(--transition-duration--faster)
          var(--transition-ease--out-quint);
        user-select: none;
      }

      &--mu {
        text-align: right;
      }
    }

    &__progress-bar {
      width: 100%;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.2);
      position: relative;
      border-radius: 1.2rem;
      cursor: pointer;

      &__current {
        width: 100%;
        height: 100%;
        border-radius: 1.2rem;
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--yellow);
        transform-origin: center left;
        transition: transform var(--transition-duration--faster)
          var(--transition-ease--out-quint);
      }

      &__clickable-area {
        width: 100%;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        opacity: 0;
        z-index: 2;
      }
    }
  }
}
