:root {
  --font-iskry: "Iskry", serif;
  --font-inter: "Inter", sans-serif;
}

.inter {
  font-family: var(--font-inter);
  font-optical-sizing: auto;
}

.iskry {
  font-family: var(--font-iskry);
}

body {
  font-family: var(--font-inter);
  color: var(--white);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-iskry);
}

i,
.italic {
  font-style: italic;
}

.thin {
  font-weight: 100;
}

.extra-light {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extra-bold {
  font-weight: 800;
}

.black {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.underlined {
  text-decoration: underline;
}

.align-center {
  text-align: center;
}

h2 {
  font-size: 2.4rem;
  line-height: 2.8rem;

  @media (--laptop) {
    font-size: 4rem;
    line-height: 5.6rem;
  }
}

h3 {
  font-size: 5.8rem;

  @media (--laptop) {
    font-size: 8rem;
  }
}

.p1 {
  font-size: 1.4rem;
}

.p2 {
  font-size: 1.6rem;

  @media (--laptop) {
    font-size: 2.4rem;
  }
}

.p3 {
  font-size: 2rem;

  @media (--laptop) {
    font-size: 3.2rem;
  }
}

.p4 {
  font-size: 1.6rem;
}

.p7 {
  font-size: 2rem;

  @media (--laptop) {
    font-size: 2.4rem;
  }
}

.p5 {
  font-size: 1.2rem;

  @media (--laptop) {
    font-size: 1.6rem;
  }
}

.p0 {
  font-size: 1rem;

  @media (--laptop) {
    font-size: 1.4rem;
  }
}

.p6 {
  font-size: 1.4rem;

  @media (--laptop) {
    font-size: 1.6rem;
  }
}

.p8 {
  font-size: 1.6rem;

  @media (--laptop) {
    font-size: 2rem;
  }
}

.p9 {
  font-size: 1.6rem;

  @media (--laptop) {
    font-size: 2.4rem;
  }
}

.p10 {
  font-size: 1.6rem;
  line-height: 1.93rem;

  @media (--laptop) {
    font-size: 3.6rem;
    line-height: 4.35rem;
  }
}

.p15 {
  font-size: 2.4rem;
  line-height: 2.19rem;

  @media (--laptop) {
    font-size: 9.6rem;
    line-height: 8.759rem;
  }
}

.p17 {
  font-size: 4rem;
  line-height: 91.2%;

  @media (--laptop) {
    font-size: 9.4rem;
  }
}

.p20 {
  font-size: 4rem;
  line-height: 3.6rem;

  @media (--laptop) {
    font-size: 12rem;
    line-height: 10.9rem;
  }
}

.p25 {
  font-size: 4.8rem;
  line-height: 4.8rem;

  @media (--laptop) {
    font-size: 6.4rem;
    line-height: 6.4rem;
  }
}
