.viridian {
  width: 100%;
  padding: 6.4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.2rem;

  @media (--laptop) {
    row-gap: 8rem;
  }

  &__paragraph {
    padding: 0 var(--margin);

    @media (--laptop) {
      width: var(--columns-8);
      padding: 0;
    }
  }

  &__carousel {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;

    &__wrapper {
      width: 100%;

      &__images {
        width: fit-content;
        display: flex;
        column-gap: 2.4rem;
        padding: 0 var(--margin);

        &__image {
          height: 22rem;

          @media (--laptop) {
            height: 44rem;
          }

          &.image img {
            position: relative;
            width: auto;
          }
        }
      }
    }

    &__arrows {
      width: 100%;
      padding: 0 var(--margin);
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__arrow {
        width: 4.5rem;
        height: 0.8rem;
        padding: 0.8rem 0;
        box-sizing: content-box;
        background-image: url(../../assets/icons/arrow--left--black.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        opacity: 1;
        will-change: opacity;
        transition: var(--transition-duration--fast)
          var(--transition-ease--out-quint);

        &--right {
          background-image: url(../../assets/icons/arrow--right--black.svg);
        }

        &--disabled {
          opacity: 0.3;
        }
      }
    }
  }
}
