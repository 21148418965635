.button {
  white-space: nowrap;
  padding: 0.8rem 1.6rem;
  border: 1px solid currentColor;
  border-radius: 10rem;
  text-align: center;
  will-change: color, background-color;
  transition: var(--transition-duration--faster)
    var(--transition-ease--out-quint);

  &:hover {
    background-color: var(--yellow);
    color: var(--black);
  }

  &--fill {
    padding: 1.6rem var(--column);
    border: none;

    @media (--laptop) {
      padding: 1.6rem 3.8rem;
    }

    &:hover {
      background-color: var(--black);
      color: var(--yellow);
    }
  }
}
