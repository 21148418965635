.checkup {
  width: 100%;
  padding: 0 var(--margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4.8rem;

  @media (--laptop) {
    padding: 2.4rem var(--margin) 4.4rem;
    row-gap: 8rem;
  }

  &__intro {
    width: var(--columns-6);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.6rem;
    text-align: center;

    @media (--laptop) {
      width: 100%;
      row-gap: 2.4rem;
    }
  }
}
