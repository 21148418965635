.link {
  &--underline {
    position: relative;
    display: inline-flex;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: currentColor;
      position: absolute;
      bottom: 0;
      left: 0;
      will-change: transform;
      transition: var(--transition-duration--fast)
        var(--transition-ease--out-quint);
      transition-property: transform;
      transform-origin: center right;
      transform: scaleX(0);
    }

    &:hover::before {
      @media (--laptop) {
        transform-origin: center left;
        transform: scaleX(1);
      }
    }
  }

  &--underlined {
    position: relative;
    display: inline-flex;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: currentColor;
      position: absolute;
      bottom: 0;
      left: 0;
      will-change: transform;
      transition: var(--transition-duration--fast)
        var(--transition-ease--out-quint);
      transition-property: transform;
    }

    &::before {
      transform-origin: center right;
      transform: scaleX(0);
    }

    &::after {
      transform-origin: center left;
      transform: scaleX(1);
      transition-delay: 0.2s;
    }

    &:hover {
      @media (--laptop) {
        &::before {
          transform-origin: center left;
          transform: scaleX(1);
          transition-delay: 0.2s;
        }

        &::after {
          transform-origin: center right;
          transform: scaleX(0);
          transition-delay: 0s;
        }
      }
    }
  }

  &--with-arrow {
    display: inline-flex;
    align-items: center;
    column-gap: 1.2rem;
  }

  &__arrows {
    display: none;

    .link--with-arrow & {
      display: block;
      position: relative;
      width: 1.6rem;
      height: 1.6rem;
    }

    &__arrow {
      width: 1.6rem;
      height: 1.6rem;
      display: block;
      background-image: url("../../assets/icons/arrow--top-right.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &__arrow {
      position: absolute;
      top: 0;
      left: 0;
      will-change: opacity, transform;
      transition-property: opacity, transform;

      &:first-of-type {
        transform: translate(-100%, 100%);
        opacity: 0;
        transition: 0.3s var(--transition-ease--in-quint);

        .link:hover & {
          @media (--laptop) {
            transition: 0.7s var(--transition-ease--out-quint) 0.2s;
            opacity: 1;
            transform: translate(0, 0);
          }
        }
      }

      &:last-of-type {
        transform: translate(0, 0);
        opacity: 1;
        transition: 0.7s var(--transition-ease--out-quint) 0.2s;

        .link:hover & {
          @media (--laptop) {
            transition: 0.3s var(--transition-ease--in-quint);
            opacity: 0;
            transform: translate(100%, -100%);
          }
        }
      }
    }
  }
}
