.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--loader-z-index);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  opacity: 1;
  color: var(--white);
  background-color: var(--black);
  will-change: opacity;
  transition: opacity var(--transition-duration--fast)
    var(--transition-ease--out-quint);

  &--hidden {
    opacity: 0;
  }

  &__logo {
    width: 50%;

    svg g g {
      animation: eye 8s 2s infinite;
    }

    @keyframes eye {
      0% {
        transform: translateX(0);
        transition-timing-function: var(--transition-ease--out-quart);
      }

      12.5% {
        transform: translateX(35%);
        transition-timing-function: var(--transition-ease--out-quart);
      }

      25% {
        transform: translateX(-35%);
        transition-timing-function: var(--transition-ease--out-quart);
      }

      37.5% {
        transform: translateX(0);
        transition-timing-function: var(--transition-ease--out-quart);
      }

      100% {
        transform: translateX(0);
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }

    svg path {
      fill: currentColor;
    }

    svg path.logo__eye-mask {
      fill: none;
    }

    svg line {
      stroke: currentColor;
    }
  }
}
